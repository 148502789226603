<template>
  <div>
    <v-alert v-if="alertAccessCode" color="#CD1041" class="mb-12">
      <div
        class="px-12 py-10 white--text"
        v-html="alertAccessCodeMessage"
      ></div>
    </v-alert>
    <v-dialog v-model="initializing" width="500" persistent>
      <v-card :loading="true" class="mx-auto">
        <template slot="progress">
          <v-progress-linear
            color="#006078"
            indeterminate
          ></v-progress-linear>
        </template>
        <v-card-text class="py-8 text-subtitle-1 text-center">
          Please wait... Initializing booking system...
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="creatingForAdmin" width="500" persistent>
      <v-card :loading="true" class="mx-auto">
        <template slot="progress">
          <v-progress-linear
            color="#006078"
            indeterminate
          ></v-progress-linear>
        </template>
        <v-card-text class="py-8 text-subtitle-1 text-center">
          Please wait... Loading locations and availability...
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="creatingForPatients" width="500" persistent>
      <v-card :loading="true" class="mx-auto">
        <template slot="progress">
          <v-progress-linear
            color="#006078"
            indeterminate
          ></v-progress-linear>
        </template>
        <v-card-text class="py-8 text-subtitle-1 text-center">
          Please wait... Processing your appointment...
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-container v-if="!bookingVisible" class="pa-0">
      <v-card v-if="checkingEligibility" :loading="true" class="my-4">
        <template slot="progress">
          <v-progress-linear color="#006078" indeterminate></v-progress-linear>
        </template>
      </v-card>
      <v-card v-if="notEligible" class="my-4 pa-4">
        <p class="ma-0 text-center title">You are not authorized to access the booking system.</p>
      </v-card>
    </v-container>
    <v-container v-if="bookingVisible" class="pa-0" fluid>
      <v-dialog v-model="creatingForPatients" width="500" persistent>
        <v-card :loading="true" class="mx-auto">
          <template slot="progress">
            <v-progress-linear
              color="#006078"
              indeterminate
            ></v-progress-linear>
          </template>
          <v-card-text class="py-8 text-subtitle-1 text-center">
            Please wait... Processing your appointment...
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-fade-transition hide-on-leave>
        <div v-if="showDateOfBirth">
          <step-date-of-birth></step-date-of-birth>
        </div>
      </v-fade-transition>
      <v-fade-transition hide-on-leave>
        <div v-if="showBehalfOfChild">
          <step-behalf-of-child></step-behalf-of-child>
        </div>
      </v-fade-transition>
      <v-fade-transition hide-on-leave>
        <div v-if="showBookingTypesGroups">
          <step-booking-types-groups></step-booking-types-groups>
        </div>
      </v-fade-transition>
      <v-fade-transition hide-on-leave>
        <div v-if="showBookingTypesMMRDose">
          <step-booking-types-m-m-r-dose></step-booking-types-m-m-r-dose>
        </div>
      </v-fade-transition>
      <v-fade-transition hide-on-leave>
        <div v-if="showBookingTypesGroupsEligibility">
          <step-booking-types-groups-eligibility></step-booking-types-groups-eligibility>
        </div>
      </v-fade-transition>
      <v-fade-transition hide-on-leave>
        <div v-if="showPregnancy">
          <step-pregnancy></step-pregnancy>
        </div>
      </v-fade-transition>
      <v-fade-transition hide-on-leave>
        <div v-if="showBookingTypesPreSelection">
          <step-booking-types-pre-selection></step-booking-types-pre-selection>
        </div>
      </v-fade-transition>
      <v-fade-transition hide-on-leave>
        <div v-if="showReceivedLastDose">
          <step-received-last-dose></step-received-last-dose>
        </div>
      </v-fade-transition>
      <v-fade-transition hide-on-leave>
        <div v-if="showReceivedLastDoseMMR">
          <step-received-last-dose-m-m-r></step-received-last-dose-m-m-r>
        </div>
      </v-fade-transition>
      <v-fade-transition hide-on-leave>
        <div v-if="showDemographics">
          <step-demographics></step-demographics>
        </div>
      </v-fade-transition>
      <v-fade-transition hide-on-leave>
        <div v-if="showCovid3DoseLetter">
          <step-covid-3-dose-letter></step-covid-3-dose-letter>
        </div>
      </v-fade-transition>
      <v-fade-transition hide-on-leave>
        <div v-if="showCovidBoosterLetter">
          <step-covid-booster-letter></step-covid-booster-letter>
        </div>
      </v-fade-transition>
      <v-fade-transition hide-on-leave>
        <div v-if="showCovidDoctorClinicianLetter">
          <step-covid-doctor-clinician-letter></step-covid-doctor-clinician-letter>
        </div>
      </v-fade-transition>
      <v-fade-transition hide-on-leave>
        <div v-if="showHealthAndSocialCareWorker">
          <step-social-care-worker></step-social-care-worker>
        </div>
      </v-fade-transition>
      <v-fade-transition hide-on-leave>
        <div v-if="showTrust">
          <step-trust></step-trust>
        </div>
      </v-fade-transition>
      <v-fade-transition hide-on-leave>
        <div v-if="showFrontlineHealthAndSocialCareWorker">
          <step-frontline-social-care-worker></step-frontline-social-care-worker>
        </div>
      </v-fade-transition>
      <v-fade-transition hide-on-leave>
        <div v-if="showTrustSelection">
          <step-trust-selection></step-trust-selection>
        </div>
      </v-fade-transition>
      <v-fade-transition hide-on-leave>
        <div v-if="showJobFamily">
          <step-job-family></step-job-family>
        </div>
      </v-fade-transition>
      <v-fade-transition hide-on-leave>
        <div v-if="showResident">
          <step-resident></step-resident>
        </div>
      </v-fade-transition>
      <v-fade-transition hide-on-leave>
        <div v-if="showResidentGBR">
          <step-resident-g-b-r></step-resident-g-b-r>
        </div>
      </v-fade-transition>
      <v-fade-transition hide-on-leave>
        <div v-if="showResidentRoI">
          <step-resident-ro-i></step-resident-ro-i>
        </div>
      </v-fade-transition>
      <v-fade-transition hide-on-leave>
        <div v-if="showBookingTypes">
          <step-booking-types></step-booking-types>
        </div>
      </v-fade-transition>
      <v-fade-transition hide-on-leave>
        <div v-if="showEligible">
          <step-eligible></step-eligible>
        </div>
      </v-fade-transition>
      <v-fade-transition hide-on-leave>
        <div v-if="showContactDetails">
          <step-contact-details
            @selected="(value) => (contactDetails = value)"
          ></step-contact-details>
        </div>
      </v-fade-transition>
      <v-fade-transition hide-on-leave>
        <div v-if="showNotEligible">
          <step-not-eligible></step-not-eligible>
        </div>
      </v-fade-transition>
      <v-fade-transition hide-on-leave>
        <div v-if="showNotEligibleAge">
          <step-not-eligible-age></step-not-eligible-age>
        </div>
      </v-fade-transition>
      <v-fade-transition hide-on-leave>
        <div v-if="showNotEligibleCovid">
          <step-not-eligible-covid></step-not-eligible-covid>
        </div>
      </v-fade-transition>
      <v-fade-transition hide-on-leave>
        <div v-if="showNotEligibleMMR">
          <step-not-eligible-m-m-r></step-not-eligible-m-m-r>
        </div>
      </v-fade-transition>
      <v-fade-transition hide-on-leave>
        <div v-if="showPertussisPregnancyStage1">
          <step-pertussis-pregnancy1></step-pertussis-pregnancy1>
        </div>
      </v-fade-transition>
      <v-fade-transition hide-on-leave>
        <div v-if="showPertussisPregnancyStage2">
          <step-pertussis-pregnancy2></step-pertussis-pregnancy2>
        </div>
      </v-fade-transition>
      <v-fade-transition hide-on-leave>
        <div v-if="showNotEligiblePertussisPregnancy">
          <step-not-eligible-pertussis-pregnancy></step-not-eligible-pertussis-pregnancy>
        </div>
      </v-fade-transition>
      <v-fade-transition hide-on-leave>
        <div v-if="showNotEligiblePertussis">
          <step-pertussis-not-eligible></step-pertussis-not-eligible>
        </div>
      </v-fade-transition>
      <v-fade-transition hide-on-leave>
        <div v-if="showNotEligiblePregnancyMMR">
          <step-not-eligible-pregnancy-m-m-r></step-not-eligible-pregnancy-m-m-r>
        </div>
      </v-fade-transition>
      <v-fade-transition hide-on-leave>
        <div v-if="showNotEligibleResidentGBRRoI">
          <step-not-eligible-resident-g-b-r-ro-i></step-not-eligible-resident-g-b-r-ro-i>
        </div>
      </v-fade-transition>
      <v-fade-transition hide-on-leave>
        <div v-if="showNotEligibleNotResident">
          <step-not-eligible-not-resident></step-not-eligible-not-resident>
        </div>
      </v-fade-transition>
      <v-fade-transition hide-on-leave>
        <div v-if="showNoAvailability">
          <step-no-availability></step-no-availability>
        </div>
      </v-fade-transition>
      <v-fade-transition hide-on-leave>
        <div v-if="showTwoStageVerify">
          <two-stage-verify
            @authed="
              (value) => {
                accessToken = value.accessToken;
                accessTokenEncrypted = value.accessTokenEncrypted;
              }
            "
            :contact-details="contactDetails"
            :date-of-birth="getDateOfBirth"
            :group-name="groupName"
            :group-name-as="groupNameAs"
          />
        </div>
      </v-fade-transition>
      <div v-if="showBookingClinics">
        <booking-clinics></booking-clinics>
      </div>
      <div v-if="showBookingSlots">
        <booking-slots></booking-slots>
      </div>
      <div v-if="showBookingSlotsWithinHour">
        <booking-slots-within-hour></booking-slots-within-hour>
      </div>
      <div v-if="showBookingPatientDetails1">
        <booking-patient-details1></booking-patient-details1>
      </div>
      <div v-if="showBookingPatientAddress">
        <booking-patient-address
          :group-name="groupName"
        ></booking-patient-address>
      </div>
      <div v-if="showBookingPatientDetails2">
        <booking-patient-details2></booking-patient-details2>
      </div>
      <div v-if="showBookingPatientSummary">
        <booking-patient-summary></booking-patient-summary>
      </div>
      <div v-if="showBookingConfirm">
        <booking-confirm></booking-confirm>
      </div>
      <div v-if="showBookingSummary">
        <booking-summary></booking-summary>
      </div>
    </v-container>
  </div>
</template>

<script>
import auth from '@/helpers/auth';
import auth4Patients from '@/helpers/auth4Patients';
import config from '@/config';
import Vue from 'vue';
import { mapGetters } from 'vuex';
import StepDateOfBirth from '@/components/steps/StepDateOfBirth';
import StepBehalfOfChild from '@/components/steps/StepBehalfOfChild';
import StepBookingTypesGroups from '@/components/steps/StepBookingTypesGroups';
import StepBookingTypesGroupsEligibility from '@/components/steps/StepBookingTypesGroupsEligibility';
import StepBookingTypesMMRDose from '@/components/steps/StepBookingTypesMMRDose';
import StepPregnancy from '@/components/steps/StepPregnancy';
import StepBookingTypesPreSelection from '@/components/steps/StepBookingTypesPreSelection';
import StepReceivedLastDose from '@/components/steps/StepReceivedLastDose';
import StepReceivedLastDoseMMR from '@/components/steps/StepReceivedLastDoseMMR';
import StepDemographics from '@/components/steps/StepDemographics';
import StepCovid3DoseLetter from '@/components/steps/StepCovid3DoseLetter';
import StepCovidBoosterLetter from '@/components/steps/StepCovidBoosterLetter';
import StepCovidDoctorClinicianLetter from '@/components/steps/StepCovidDoctorClinicianLetter';
import StepSocialCareWorker from '@/components/steps/StepSocialCareWorker';
import StepTrust from '@/components/steps/StepTrust';
import StepFrontlineSocialCareWorker from '@/components/steps/StepFrontlineSocialCareWorker';
import StepTrustSelection from '@/components/steps/StepTrustSelection';
import StepJobFamily from '@/components/steps/StepJobFamily';
import StepResident from '../components/steps/StepResident';
import StepResidentGBR from '../components/steps/StepResidentGBR';
import StepResidentRoI from '../components/steps/StepResidentRoI';
import StepBookingTypes from '@/components/steps/StepBookingTypes';
import StepEligible from '@/components/steps/StepEligible';
import StepContactDetails from '@/components/steps/StepContactDetails';
import StepPertussisPregnancy1 from '@/components/steps/StepPertussisPregnancy1';
import StepPertussisPregnancy2 from '@/components/steps/StepPertussisPregnancy2';
import StepNotEligible from '@/components/steps/no-and-not/StepNotEligible';
import StepNotEligibleAge from '@/components/steps/no-and-not/StepNotEligibleAge';
import StepNotEligibleCovid from '@/components/steps/no-and-not/StepNotEligibleCovid';
import StepNotEligibleMMR from '@/components/steps/no-and-not/StepNotEligibleMMR';
import StepPertussisNotEligible from '@/components/steps/no-and-not/StepNotEligiblePertussis';
import StepNotEligiblePregnancyMMR from '@/components/steps/no-and-not/StepNotEligiblePregnancyMMR';
import StepNotEligiblePertussisPregnancy from '@/components/steps/no-and-not/StepNotEligiblePertussisPregnancy';
import StepNotEligibleNotResident from '@/components/steps/no-and-not/StepNotEligibleNotResident';
import StepNotEligibleResidentGBRRoI from '@/components/steps/no-and-not/StepNotEligibleResidentGBRRoI';
import StepNoAvailability from '@/components/steps/no-and-not/StepNoAvailability';
import TwoStageVerify from '@/components/TwoStageVerify';
import BookingClinics from '@/components/booking/clinics/Clinics';
import BookingSlots from '@/components/booking/slots/Slots';
import BookingSlotsWithinHour from '@/components/booking/slots/SlotsWithinHour';
import BookingPatientDetails1 from '@/components/booking/patient/PatientDetails1';
import BookingPatientDetails2 from '@/components/booking/patient/PatientDetails2';
import BookingPatientAddress from '@/components/booking/patient/PatientAddress';
import BookingPatientSummary from '@/components/booking/patient/PatientSummary';
import BookingConfirm from '@/components/booking/summary/Confirm';
import BookingSummary from '@/components/booking/summary/Summary';
import GROUPS from '@/data/groups';
import { calculateAge } from '@/helpers/ageCalculation.ts';
import { getSesKey, remSesKey, getCallRecallUrl, remCallRecallUrl } from '@/helpers/session.ts';
import { uniqId } from '@/helpers/patient.ts'

export default {
  name: 'Home',
  components: {
    StepDateOfBirth,
    StepBehalfOfChild,
    StepBookingTypesGroups,
    StepBookingTypesGroupsEligibility,
    StepPregnancy,
    StepBookingTypesMMRDose,
    StepBookingTypesPreSelection,
    StepReceivedLastDose,
    StepReceivedLastDoseMMR,
    StepDemographics,
    StepCovid3DoseLetter,
    StepCovidBoosterLetter,
    StepCovidDoctorClinicianLetter,
    StepSocialCareWorker,
    StepTrust,
    StepFrontlineSocialCareWorker,
    StepTrustSelection,
    StepJobFamily,
    StepResident,
    StepResidentGBR,
    StepResidentRoI,
    StepBookingTypes,
    StepPertussisPregnancy1,
    StepPertussisPregnancy2,
    StepEligible,
    StepContactDetails,
    StepNotEligible,
    StepNotEligibleAge,
    StepNotEligibleCovid,
    StepNotEligibleMMR,
    StepPertussisNotEligible,
    StepNotEligiblePregnancyMMR,
    StepNotEligibleNotResident,
    StepNotEligibleResidentGBRRoI,
    StepNotEligiblePertussisPregnancy,
    StepNoAvailability,
    TwoStageVerify,
    BookingClinics,
    BookingSlots,
    BookingSlotsWithinHour,
    BookingPatientDetails1,
    BookingPatientDetails2,
    BookingPatientAddress,
    BookingPatientSummary,
    BookingConfirm,
    BookingSummary
  },
  data: () => ({
    scanId: 17004,
    regionId: 1,
    bookingVisible: false,
    checkingEligibility: false,
    notEligible: null,
    routerStage: false,
    contactDetails: null,
    accessToken: null,
    accessTokenEncrypted: null,
    alertInitialize: false,
    alertInitializeMessage: '',
    alertAccessCode: false,
    alertAccessCodeMessage: '',
    initializing: false,
    creatingForAdmin: false,
    creatingForPatients: false
  }),
  watch: {
    '$route.params.stage': {
      handler: async function(stage) {
        if (stage === 'date-of-birth') {
            remSesKey();
            remCallRecallUrl();
            localStorage.removeItem('accessCode');
            localStorage.removeItem('patientAccessCode');
            this.$store.dispatch('clearState');
            this.$store.dispatch('clearAppointment');
            this.accessToken = null;
            this.accessTokenEncrypted = null;
        }

        if (stage === 'date-of-birth' 
         && this.accessToken4Patients()) {
          auth4Patients.clearToken();
        }

        if (stage === 'auth') {
          this.$store.dispatch('clearAppointment');
          this.accessToken = null;
          this.accessTokenEncrypted = null;
        }

        if (
          stage !== 'date-of-birth') {
          if (!this.getDateOfBirth && !this.getPatient.patientDetails) {
            const url = getCallRecallUrl() || '/booking';
            this.$router.push(url).catch(() => {});
          }
        }

        Vue.nextTick(async () => {
          if (this.accessToken4Admin() && !this.accessToken) {
            const ifEligible = await this.checkIfEligibleForAdmin();
            if (ifEligible) {
              //for admin and standard flow we dont want the session from call/recall
              await this.createForAdmin();
              await this.getBookingApiInitData();
            }
          }

          if (this.accessToken4Patients() && !this.accessToken) {
            await this.createForPatients();
            await this.getBookingApiInitData();
          }

          if (this.accessTokenEncrypted && !this.getBookingApiURLs) {
            //for admin and standard flow we dont want the session from call/recall
            await this.getBookingApiInitData();
          }
        });

        this.bookingVisible = true;
        this.routerStage = stage;

        Vue.nextTick(() => {
          window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
          });
        });
      },
      deep: true,
      immediate: true
    }
  },
  async mounted() {
    this.$gtag.event('Page enter', {
      'value' : `ID : ${uniqId}`
    })
  },
  computed: {
    ...mapGetters([
      'getDateOfBirth',
      'getAge',
      'getPreSelectedBookingTypes',
      'getIfCovid3DoseLetter',
      'getIfCovidBoosterLetter',
      'getIfCovidDoctorClinicianLetter',
      'getHealthAndSocialCareWorker',
      'getFrontlineHealthAndSocialCareWorker',
      'getTrust',
      'getIfTrustPrimaryEmployer',
      'getJobFamily',
      'getIfResident',
      'getBookingTypes',
      'getIfReceived1Dose',
      'getIfReceived2Dose',
      'getIfReceived3Dose',
      'getBookingApiURLs',
      'getSlot',
      'getClinic',
      'getPatient',
      'getAppointment',
      'getQuickSchedule',
      'getBookingDetailsJSON'
    ]),
    bookingScope() {
      if (this.$route.path !== '/booking' && this.accessToken4Admin()) {
        return 'ADMIN';
      }
      if (this.$route.path !== '/booking' && this.accessToken4Patients()) {
        return 'PATIENT';
      }
      return '';
    },
    groupName() {
      const groupNameObj = GROUPS.filter(
        (f) =>
          f.ifDate === true &&
          this.getDateOfBirth &&
          this.getDateOfBirth >= f.minDateAsDate &&
          this.getDateOfBirth <= f.maxDateAsDate
      )[0];
      return groupNameObj ? groupNameObj.text : null;
    },
    groupNameAs() {
      const groupNameObj = GROUPS.filter(
        (f) =>
          f.ifDate === true &&
          this.getDateOfBirth &&
          this.getDateOfBirth >= f.minDateAsDate &&
          this.getDateOfBirth <= f.maxDateAsDate
      )[0];
      return groupNameObj ? groupNameObj.textAt : null;
    },
    dateOfBirthAge() {
      if (!this.getDateOfBirth) {
        return 0;
      } else {
        return calculateAge(this.getDateOfBirth);
      }
    },
    bookingApiUrl() {
      let url = config.bookingUrl;
      if (this.accessTokenEncrypted) {
        url += '&accessTokenEncrypted=' + encodeURIComponent(this.accessTokenEncrypted);
      }
      if (this.accessToken && !this.accessTokenEncrypted) {
        url += '&accessToken=' + encodeURIComponent(this.accessToken);
      }
      return url;
    },
    showDateOfBirth() {
      return !this.routerStage || this.routerStage === 'date-of-birth';
    },
    showBehalfOfChild() {
      return this.routerStage === 'behalf-of-child';
    },
    showBookingTypesGroups() {
      return this.routerStage === 'booking-types-groups';
    },
    showBookingTypesGroupsEligibility() {
      return this.routerStage === 'booking-types-groups-eligibility';
    },
    showBookingTypesMMRDose() {
      return this.routerStage === 'booking-types-mmr-dose';
    },
    showPregnancy() {
      return this.routerStage === 'pregnancy';
    },
    showBookingTypesPreSelection() {
      return this.routerStage === 'booking-types-1';
    },
    showReceivedLastDose() {
      return this.routerStage === 'received-last-dose';
    },
    showReceivedLastDoseMMR() {
      return this.routerStage === 'received-last-dose-mmr';
    },
    showDemographics() {
      return this.routerStage === 'demographics';
    },
    showCovid3DoseLetter() {
      return this.routerStage === 'covid-3-dose-letter';
    },
    showCovidBoosterLetter() {
      return this.routerStage === 'covid-booster-letter';
    },
    showCovidDoctorClinicianLetter() {
      return this.routerStage === 'covid-doctor-clinician-letter';
    },
    showHealthAndSocialCareWorker() {
      return this.routerStage === 'social-care-worker';
    },
    showTrust() {
      return this.routerStage === 'trust';
    },
    showFrontlineHealthAndSocialCareWorker() {
      return this.routerStage === 'frontline-social-care-worker';
    },
    showTrustSelection() {
      return this.routerStage === 'trust-selection';
    },
    showJobFamily() {
      return this.routerStage === 'job-family';
    },
    showResident() {
      return this.routerStage === 'resident';
    },
    showResidentGBR() {
      return this.routerStage === 'resident-gbr';
    },
    showResidentRoI() {
      return this.routerStage === 'resident-roi';
    },
    showBookingTypes() {
      return this.routerStage === 'booking-types-2';
    },
    showEligible() {
      return this.routerStage === 'eligible';
    },
    showContactDetails() {
      return this.routerStage === 'contact-details';
    },
    showTwoStageVerify() {
      return this.routerStage === 'auth';
    },
    showNotEligible() {
      return this.routerStage === 'not-eligible';
    },
    showNotEligibleAge() {
      return this.routerStage === 'not-eligible-age';
    },
    showNotEligibleCovid() {
      return this.routerStage === 'not-eligible-covid';
    },
    showNotEligibleMMR() {
      return this.routerStage === 'not-eligible-mmr';
    },
    showNotEligiblePertussis() {
      return this.routerStage === 'pertussis-not-eligible';
    },
    showNotEligiblePregnancyMMR() {
      return this.routerStage === 'not-eligible-pregnancy-mmr';
    },
    showNotEligiblePertussisPregnancy() {
      return this.routerStage === 'pertussis-pregnancy-not-eligible';
    },
    showPertussisPregnancyStage1() {
      return this.routerStage === 'pertussis-pregnancy-1';
    },
    showPertussisPregnancyStage2() {
      return this.routerStage === 'pertussis-pregnancy-2';
    },
    showNotEligibleResidentGBRRoI() {
      return this.routerStage === 'not-eligible-resident';
    },
    showNotEligibleNotResident() {
      return this.routerStage === 'not-eligible-not-resident';
    },
    showNoAvailability() {
      return this.routerStage === 'no-availability';
    },
    showBookingClinics() {
      return this.routerStage === 'clinics' && this.getBookingApiURLs;
    },
    showBookingSlots() {
      return this.routerStage === 'slots' && this.getBookingApiURLs;
    },
    showBookingSlotsWithinHour() {
      return this.routerStage === 'slots-2' && this.getBookingApiURLs;
    },
    showBookingPatientDetails1() {
      return this.routerStage === 'patient-details-1' && this.getBookingApiURLs;
    },
    showBookingPatientDetails2() {
      return this.routerStage === 'patient-details-2' && this.getBookingApiURLs;
    },
    showBookingPatientAddress() {
      return this.routerStage === 'patient-address' && this.getBookingApiURLs;
    },
    showBookingPatientSummary() {
      return this.routerStage === 'patient-summary' && this.getBookingApiURLs;
    },
    showBookingConfirm() {
      return this.routerStage === 'confirm';
    },
    showBookingSummary() {
      return this.routerStage === 'summary';
    }
  },
  methods: {
    accessToken4Admin() {
      if (!auth.verifySavedToken()) {
        return false;
      }
      return localStorage.getItem(config.authTokenName);
    },
    accessToken4Patients() {
      if (!auth4Patients.verifySavedToken()) {
        return false;
      }
      return localStorage.getItem(config.patientAuthTokenName);
    },
    showInitializeError() {
      this.alertInitializeMessage = 'Sorry, the verification was not successful. Reload the page and try again.';
      this.alertInitialize = true;
    },
    hideInitializeError() {
      this.alertInitializeMessage = '';
      this.alertInitialize = false;
    },
    showAccessCodeError() {
      this.alertAccessCodeMessage = 'Sorry, could not initialize booking system. Reload the page and try again.';
      this.alertAccessCode = true;
    },
    hideAccessCodeError() {
      this.alertAccessCodeMessage = '';
      this.alertAccessCode = false;
    },
    dateOfBirthFormatted(dateOfBirth) {
      const year = dateOfBirth.getFullYear();
      let month = dateOfBirth.getMonth() + 1;
      let day = dateOfBirth.getDate();
      if (month < 10) {
        month = '0' + (month + '');
      }
      if (day < 10) {
        day = '0' + (day + '');
      }
      return day + '/' + month + '/' + year;
    },
    async getBookingApiInitData() {
      this.initializing = true;
      let result = null;
      try {
        const resultFetch = await fetch(this.bookingApiUrl, {
          method: 'POST',
          mode: 'cors',
          credentials: 'include',
          cache: 'no-cache',
          headers: {
            'Content-Type': 'application/json',
            'Scope': this.bookingScope,
            'Key': getSesKey()
          },
          redirect: 'follow'
        });
        result = await resultFetch.json();
      }
      catch {
        this.initializing = false;
        this.showInitializeError();
        return;
      }

      this.initializing = false;
      this.hideInitializeError();

      this.$store.commit('setBookingApiURLs', result.response.urls);
      this.$store.commit(
        'setAppointmentProcedure',
        result.response.appointmentProcedure
      );
    },
    async checkIfEligibleForAdmin() {
      this.checkingEligibility = true;
      let result = null;
      try {
        const resultFetch = await fetch(config.adminApiUrl + '/otp/', {
          method: 'POST',
          mode: 'cors',
          credentials: 'include',
          cache: 'no-cache',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.accessToken4Admin(),
            'Key': getSesKey()
          },
          redirect: 'follow',
          body: JSON.stringify({
            action: 'checkIfEligible'
          })
        });
        result = await resultFetch.json();
      }
      catch {
        this.bookingVisible = false;
        this.notEligible = true;
        this.checkingEligibility = false;
        return false;
      }

      if (result.status !== 'S' || !result.response.ifEligible) {
        this.bookingVisible = false;
        this.notEligible = true;
        this.checkingEligibility = false;
        return false;
      }
      else {
        this.bookingVisible = true;
        this.checkingEligibility = false;
        return true;
      }      
    },
    async createForAdmin() {
      this.creatingForAdmin = true;
      let result = null;
      try {
        const resultFetch = await fetch(config.adminApiUrl + '/otp/', {
          method: 'POST',
          mode: 'cors',
          credentials: 'include',
          cache: 'no-cache',
          headers: {
            'Content-Type': 'application/json',
            'Scope': 'ADMIN',
            'Authorization': 'Bearer ' + this.accessToken4Admin(),
            'Key': getSesKey()
          },
          redirect: 'follow',
          body: JSON.stringify({
            action: 'create',
            data: {
              booking_details_json: this.getBookingDetailsJSON,
              booking_types: this.getBookingTypes
                ? this.getBookingTypes.join(',')
                : null,
              date_of_birth: this.getDateOfBirth
                ? this.dateOfBirthFormatted(this.getDateOfBirth)
                : null,
              custom_1: this.getDemographics
                ? this.getDemographics.join(',')
                : null,
              custom_8: this.getJobFamily,
              custom_19: this.getHealthAndSocialCareWorker ? 1 : 0,
              custom_25: this.getTrust,
              custom_31: this.groupName,
              custom_32: this.groupNameAs
            }
          })
        });
        result = await resultFetch.json();
      }
      catch {
        this.creatingForAdmin = false;
        this.showAccessCodeError();
        return;
      }

      const patientAddress = this.getPatient.patientAddress || {
        stepsMobilePhone: null,
        stepsEmailAddress: null
      };

      this.creatingForAdmin = false;
      this.accessToken = result.response.accessToken;
      this.$store.commit('setPatientAddress', patientAddress);
    },
    async createForPatients() {
      this.creatingForPatients = true;
      let result = null;
      try {
        const resultFetch = await fetch(config.adminApiUrl + '/otp/', {
          method: 'POST',
          mode: 'cors',
          credentials: 'include',
          cache: 'no-cache',
          headers: {
            'Content-Type': 'application/json',
            'Scope': 'PATIENT',
            'Authorization': 'Bearer ' + this.accessToken4Patients(),
            'Key': getSesKey()
          },
          redirect: 'follow',
          body: JSON.stringify({
            action: 'create',
            accessCode: localStorage.getItem('accessCode')
          })
        });
        result = await resultFetch.json();
      }
      catch {
        this.creatingForPatients = false;
        this.showAccessCodeError();
        return;
      }

      const patientAddress = this.getPatient.patientAddress || {
        stepsMobilePhone: null,
        stepsEmailAddress: null
      };

      this.creatingForPatients = false;
      this.accessToken = result.response.accessToken;
      this.$store.commit('setPatientAddress', patientAddress);
    }
  }
};
</script>
