<template>
  <div>
    <v-container
      fluid
      class="ma-0 pa-0 mb-16 jumbo-header"
      style="min-height: 370px; line-height: 1.2 !important; background-color: #F9F9F9;"
    >
      <v-row justify="center" align="center" class="fill-height">
        <v-col class="pt-8 pb-16">
          <v-container>
            <back-button
              class="my-8"
              @click.native="$router.go(-1)"
            ></back-button>
            <v-row>
              <v-col cols="12" lg="10">
                <h2 class="home-header-2">
                  Thank-you, based on your selection you are eligible to book.
                </h2>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <div v-if="!accessToken">
        <p class="mb-2 home-text-2">
          We now need to verify your contact details.
        </p>
        <p class="mb-12 home-text-2">
          Please have your mobile number or email address ready.
        </p>
      </div>
      <v-btn @click="onAfterContinueClicked" class="v-btn--primary">
        Continue
      </v-btn>
    </v-container>
  </div>
</template>

<script>
import auth from '@/helpers/auth';
import config from '@/config';
import Vue from 'vue';
import BackButton from '@/components/BackButton';
import { mapGetters } from 'vuex';

export default Vue.extend({
  components: {
    BackButton
  },
  name: 'StepEligible',
  data: () => ({
    ifEligible: null
  }),
  computed: {
    accessToken() {
      if (!auth.verifySavedToken()) {
        return false;
      }
      return localStorage.getItem(config.authTokenName);
    },
    ...mapGetters(['getFlowName'])
  },
  methods: {
    onAfterContinueClicked() {
      this.$emit('ifEligible', true);

      if (this.accessToken) {
        this.$router.push('/booking/auth').catch(() => {});
      } else {
        this.$router.push('/booking/contact-details').catch(() => {});
      }
    },
    mounted(){
      this.$gtag.event(`Patient eligible to book` + `${this.getFlowName}`, {})
    }
  }
});
</script>
