<template>
  <v-row justify="center">
    <v-col cols="12" class="pt-16">
      <v-container>
        <back-button @click.native="$router.go(-1)"></back-button>
        <v-row>
          <v-col cols="12" lg="6">
            <h3 class="mb-8 home-header-3">
              Have you received a letter from your Trust doctor/clinician
              specifically stating that you are eligible for a COVID-19 Booster
              because you live in a household with somebody who is severely
              immunosuppressed?
            </h3>
            <v-radio-group
              v-model="ifCovidBoosterLetter"
              v-on:change="onRadioGroupClicked"
              class="mb-4"
            >
              <v-radio
                v-on:keyup.enter="
                  ifCovidBoosterLetter = 'Yes';
                  onRadioGroupClicked();
                "
                label="Yes"
                value="Yes"
                color="#006078"
                class="ml-4 px-8 py-4 black--text"
                :ripple="false"
              ></v-radio>
              <v-radio
                v-on:keyup.enter="
                  ifCovidBoosterLetter = 'No';
                  onRadioGroupClicked();
                "
                label="No"
                value="No"
                color="#006078"
                class="ml-4 px-8 py-4 black--text"
                :ripple="false"
              ></v-radio>
            </v-radio-group>
            <v-btn
              @click="onAfterContinueClicked"
              class="v-btn--primary"
              :disabled="!ifCovidBoosterLetter"
            >
              Continue
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import BackButton from '@/components/BackButton';

export default Vue.extend({
  components: {
    BackButton
  },
  name: 'StepCovidBoosterLetter',
  data: () => ({
    ifCovidBoosterLetter: null
  }),
  computed: {
    ...mapGetters([
      'getAge',
      'getBehalfOfChild'
    ])
  },
  mounted: function() {
    this.$store.commit('setIfCovidBoosterLetter', null);
    this.$store.commit('setIfCovidDoctorClinicianLetter', null);
  },
  methods: {
    onRadioGroupClicked() {
      this.$store.commit(
        'setIfCovidBoosterLetter',
        this.ifCovidBoosterLetter === 'Yes'
      );
    },
    onAfterContinueClicked() {
      this.$gtag.event('Covid booster letter answered', {})

      if (this.ifCovidBoosterLetter === 'No' && this.getBehalfOfChild) {
        this.$router.push('/booking/covid-doctor-clinician-letter').catch(() => {});
      }
      else {
        if (this.getAge >= 18) {
          this.$router.push('/booking/social-care-worker').catch(() => {});
        } else {
          this.$router.push('/booking/resident').catch(() => {});
        }
      }
    }
  }
});
</script>
