<template>
  <div>
    <v-container
      v-if="
        !showSeasonalFlu &&
        !showCOVIDUnder12Dose1 &&
        !showCOVIDUnder12Dose2 &&
        !showCOVID1Dose &&
        !showCOVID2Dose &&
        !showCOVID3Dose &&
        !showCOVIDBooster &&
        !showCOVIDUnder18Booster
      "
      fluid
      class="ma-0 pa-0 mb-16 jumbo-header"
      style="background-color: #F9F9F9;"
    >
      <v-row justify="center" align="center" class="fill-height">
        <v-col class="pt-8 pb-16">
          <v-container>
            <back-button
              class="my-8"
              @click.native="$router.go(-1)"
            ></back-button>
            <v-row>
              <v-col cols="12" lg="9">
                <h2 class="mb-8 home-header-2">
                  Unfortunately you are not eligible to book using this service
                  at this time. You may be eligible to book at a local pharmacy
                  or your GP.
                </h2>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
    <v-row v-else justify="center">
      <v-col cols="12" class="pt-16">
        <v-container>
          <back-button @click.native="$router.go(-1)"></back-button>
          <v-row>
            <v-col cols="12" lg="6">
              <h3 class="mb-8 home-header-3">
                Which vaccinations are you booking today?
              </h3>
              <div class="mb-8">
                <!-- seasonal flu is to be disabled for now, per client request -->
                <!-- https://aptvision.atlassian.net/browse/RIS-8652 -->
                <v-checkbox
                  v-if="false"
                  v-model="bookingTypes"
                  v-on:change="onCheckboxGroupClicked()"
                  v-on:keyup.native.enter="
                    addRemoveBookingTypes('FLU');
                    onCheckboxGroupClicked();
                  "
                  label="Seasonal Flu"
                  value="FLU"
                  color="#006078"
                  class="ml-4 px-8 black--text"
                  :ripple="false"
                ></v-checkbox>
                <v-checkbox
                  v-if="showCOVIDUnder12Dose1"
                  v-model="bookingTypes"
                  v-on:change="onCheckboxGroupClicked()"
                  v-on:keyup.native.enter="
                    addRemoveBookingTypes('UNDER12DOSE1');
                    onCheckboxGroupClicked();
                  "
                  label="COVID Dose 1"
                  value="UNDER12DOSE1"
                  color="#006078"
                  class="ml-4 px-8 black--text"
                  :ripple="false"
                ></v-checkbox>
                <v-checkbox
                  v-if="showCOVIDUnder12Dose2"
                  v-model="bookingTypes"
                  v-on:change="onCheckboxGroupClicked()"
                  v-on:keyup.native.enter="
                    addRemoveBookingTypes('UNDER12DOSE2');
                    onCheckboxGroupClicked();
                  "
                  label="COVID Dose 2"
                  value="UNDER12DOSE2"
                  color="#006078"
                  class="ml-4 px-8 black--text"
                  :ripple="false"
                ></v-checkbox>
                <v-checkbox
                  v-if="showCOVID1Dose"
                  v-model="bookingTypes"
                  v-on:change="onCheckboxGroupClicked()"
                  v-on:keyup.native.enter="
                    addRemoveBookingTypes('DOSE1');
                    onCheckboxGroupClicked();
                  "
                  label="COVID Dose 1"
                  value="DOSE1"
                  color="#006078"
                  class="ml-4 px-8 black--text"
                  :disabled="disableCOVID1Dose"
                  :ripple="false"
                ></v-checkbox>
                <v-checkbox
                  v-if="showCOVID2Dose"
                  v-model="bookingTypes"
                  v-on:change="onCheckboxGroupClicked()"
                  v-on:keyup.native.enter="
                    addRemoveBookingTypes('DOSE2');
                    onCheckboxGroupClicked();
                  "
                  label="COVID Dose 2"
                  value="DOSE2"
                  color="#006078"
                  class="ml-4 px-8 black--text"
                  :disabled="disableCOVID2Dose"
                  :ripple="false"
                ></v-checkbox>
                <v-checkbox
                  v-if="showCOVID3Dose"
                  v-model="bookingTypes"
                  v-on:change="onCheckboxGroupClicked()"
                  v-on:keyup.native.enter="
                    addRemoveBookingTypes('DOSE3');
                    onCheckboxGroupClicked();
                  "
                  label="COVID 3rd primary dose"
                  value="DOSE3"
                  color="#006078"
                  class="ml-4 px-8 black--text"
                  :disabled="disableCOVID3Dose"
                  :ripple="false"
                ></v-checkbox>
                <v-checkbox
                  v-if="showCOVIDBooster && !showCOVID3Dose"
                  v-model="bookingTypes"
                  v-on:change="onCheckboxGroupClicked()"
                  v-on:keyup.native.enter="
                    addRemoveBookingTypes('BOOSTER');
                    onCheckboxGroupClicked();
                  "
                  label="COVID Booster"
                  value="BOOSTER"
                  color="#006078"
                  class="ml-4 px-8 black--text"
                  :disabled="disableCOVIDBooster"
                  :ripple="false"
                ></v-checkbox>
                <v-checkbox
                  v-if="showCOVIDUnder18Booster && !showCOVID3Dose"
                  v-model="bookingTypes"
                  v-on:change="onCheckboxGroupClicked()"
                  v-on:keyup.native.enter="
                    addRemoveBookingTypes('UNDER18BOOSTER');
                    onCheckboxGroupClicked();
                  "
                  label="COVID Booster"
                  value="UNDER18BOOSTER"
                  color="#006078"
                  class="ml-4 px-8 black--text"
                  :disabled="disableCOVIDUnder18Booster"
                  :ripple="false"
                ></v-checkbox>
              </div>
              <v-btn
                @click="onAfterContinueClicked"
                class="v-btn--primary"
                :disabled="!bookingTypes.length"
              >
                Continue
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import BackButton from '@/components/BackButton';
import moment from 'moment/moment';

export default Vue.extend({
  components: {
    BackButton
  },
  name: 'StepBookingTypes',
  data: () => ({
    bookingTypes: []
  }),
  computed: {
    ...mapGetters([
      'getDemographics',
      'getPreSelectedBookingTypes',
      'getDateWhenLastDoseReceived',
      'getIfCovid3DoseLetter',
      'getIfCovidBoosterLetter',
      'getIfCovidDoctorClinicianLetter',
      'getHealthAndSocialCareWorker',
      'getIfTrustPrimaryEmployer',
      'getFrontlineHealthAndSocialCareWorker',
      'getDateOfBirth',
      'getAge',
      'getAgeInMonths',
      'getFlowName'
    ]),
    disableCOVID1Dose() {
      return (
        this.bookingTypes.includes('DOSE2') ||
        this.bookingTypes.includes('DOSE3') ||
        this.bookingTypes.includes('BOOSTER')
      );
    },
    disableCOVID2Dose() {
      return true;
      // return (
      //   this.bookingTypes.includes('DOSE1') ||
      //   this.bookingTypes.includes('DOSE3') ||
      //   this.bookingTypes.includes('BOOSTER')
      // );
    },
    disableCOVID3Dose() {
      return (
        this.bookingTypes.includes('DOSE1') ||
        this.bookingTypes.includes('DOSE2') ||
        this.bookingTypes.includes('BOOSTER')
      );
    },
    disableCOVIDBooster() {
      return (
        this.bookingTypes.includes('DOSE1') ||
        this.bookingTypes.includes('DOSE2') ||
        this.bookingTypes.includes('DOSE3')
      );
    },
    disableCOVIDUnder18Booster() {
      return (
        this.bookingTypes.includes('DOSE1') ||
        this.bookingTypes.includes('DOSE2') ||
        this.bookingTypes.includes('DOSE3')
      );
    },
    showSeasonalFlu() {
      // disabled per https://aptvision.atlassian.net/browse/RIS-8652
      return false 
    },
    showCOVIDUnder12Dose1() {
      if (
        !this.getPreSelectedBookingTypes.includes('COVID Dose 1') &&
        !this.getPreSelectedBookingTypes.includes('COVID Dose 2') &&
        !this.getPreSelectedBookingTypes.includes('COVID 3rd primary dose') &&
        !this.getPreSelectedBookingTypes.includes('COVID Booster') &&
        this.ifOlderThan5YearsSince01_09 &&
        this.getAge < 12 &&
        (
          this.getIfCovidBoosterLetter ||
          this.getDemographics.includes('At Risk') ||
          this.getDemographics.includes('Immunosuppressed')
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    showCOVIDUnder12Dose2() {
      return false;
    },
    showCOVID1Dose() {
      if (
        this.getPreSelectedBookingTypes.includes('COVID Dose 1') ||
        this.getPreSelectedBookingTypes.includes('COVID Dose 2') ||
        this.getPreSelectedBookingTypes.includes('COVID 3rd primary dose') ||
        this.getPreSelectedBookingTypes.includes('COVID Booster')
      ) {
          return false;
      }
      else if (this.getAge >= 65) {
        return true;
      }
      else if (
        (this.getAge >= 12) &&
        (
          (this.getDemographics.includes('Care Home Resident')) ||
          (this.getFrontlineHealthAndSocialCareWorker) ||
          (this.getDemographics.includes('Pregnant')) ||
          ( 
            this.getDemographics.includes('Foster Carer') &&
            this.getAge >= 16
          ) ||
          ( 
            this.getDemographics.includes('Family Carer') &&
            this.getAge >= 16
          ) ||
          (this.getIfCovidBoosterLetter) ||
          ( 
            this.getDemographics.includes('At Risk')
          ) ||
          ( 
            this.getDemographics.includes('Immunosuppressed')
          )
        )
      ) {
        return true;
      }
      else {
        return false;
      }      
    },
    showCOVID2Dose() {
      return false;
    },
    showCOVID3Dose() {
      return false;
    },
    // this is under12 booster
    showCOVIDUnder18Booster() {

      if (
        this.getPreSelectedBookingTypes.includes('COVID Dose 1') &&
        this.ifMoreThan3MonthsSinceLastDose &&
        this.getAge < 12 &&
        this.getDemographics.includes('Immunosuppressed')
      ) {
        return true;
      }
      else {
        return false;
      }
    },
    showCOVIDBooster() {
      if (
        this.getPreSelectedBookingTypes.includes('COVID Dose 1') &&
        this.ifMoreThan3MonthsSinceLastDose &&
        this.getAge >= 12 &&
        ( 
          (this.getAge >= 75) ||
          (this.getDemographics.includes('Care Home Resident')) ||
          (this.getDemographics.includes('Immunosuppressed'))
        )
      ) {
        return true;
      }
      else {
        return false;
      }
    },
    ifMoreThan12WeeksSinceLastDose() {
      return moment()
        .startOf('day')
        .subtract(12, 'weeks') >= this.getDateWhenLastDoseReceived;
    },
    ifMoreThan8WeeksSinceLastDose() {
      return moment()
        .startOf('day')
        .subtract(8, 'weeks') >= this.getDateWhenLastDoseReceived;
    },
    ifMoreThan3MonthsSinceLastDose() {
      return moment()
        .startOf('day')
        .subtract(3, 'months') >= this.getDateWhenLastDoseReceived;
    },
    ifOlderThan74YearsAnd11Months() {
      const referenceDate = moment('1947-06-30', 'YYYY-MM-DD');
      return this.getDateOfBirth <= referenceDate;
    },
    ifOlderThan5YearsSince01_09() {
      const referenceDate = moment('2017-09-01', 'YYYY-MM-DD');
      return this.getDateOfBirth <= referenceDate;
    },
  },
  methods: {
    addRemoveBookingTypes(value) {
      const index = this.bookingTypes.indexOf(value);
      if (index > -1) {
        this.bookingTypes.splice(index, 1);
      } else {
        this.bookingTypes.push(value);
      }
    },
    saveBookingTypes() {
      this.$store.commit('setBookingTypes', this.bookingTypes);
    },
    onCheckboxGroupClicked() {
      this.saveBookingTypes();
    },
    onAfterContinueClicked() {
      this.$gtag.event(`Booking type selected` + `${this.getFlowName}`, {})
      this.$router.push('/booking/eligible').catch(() => {});
    }
  }
});
</script>
