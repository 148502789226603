<template>
  <v-row justify="center">
    <v-col cols="12" class="pt-16">
      <v-container>
        <back-button @click.native="$router.go(-1)"></back-button>
        <h3 class="mb-8 home-header-3">
          Have you received a letter from your doctor/clinician specifically
          stating that you are eligible for a COVID vaccination?
        </h3>
        <v-radio-group
          v-model="covidDoctorClinicianLetter"
          v-on:change="onRadioGroupClicked"
          class="mb-4"
        >
          <v-radio
            v-on:keyup.enter="
              covidDoctorClinicianLetter = 'Yes';
              onRadioGroupClicked();
            "
            label="Yes"
            value="Yes"
            color="#006078"
            class="ml-4 px-8 py-4 black--text"
            :ripple="false"
          ></v-radio>
          <v-radio
            v-on:keyup.enter="
              covidDoctorClinicianLetter = 'No';
              onRadioGroupClicked();
            "
            label="No"
            value="No"
            color="#006078"
            class="ml-4 px-8 py-4 black--text"
            :ripple="false"
          ></v-radio>
        </v-radio-group>
        <v-btn
          @click="onAfterContinueClicked"
          class="v-btn--primary"
          :disabled="!covidDoctorClinicianLetter"
        >
          Continue
        </v-btn>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import BackButton from '@/components/BackButton';

export default Vue.extend({
  components: { BackButton },
  name: 'StepCovidDoctorClinicianLetter',
  data: () => ({
    covidDoctorClinicianLetter: null
  }),
    computed: {
    ...mapGetters([
    'getAge'
    ])
  },
  mounted: function() {
    this.$store.commit('setIfCovidDoctorClinicianLetter', null);
  },
  methods: {
    onRadioGroupClicked() {
      this.$store.commit(
        'setIfCovidDoctorClinicianLetter',
        this.covidDoctorClinicianLetter === 'Yes'
      );
    },
    onAfterContinueClicked() {
      this.$gtag.event('Covid vaccination eligible recommendation question answered', {})
      if (this.getAge >= 18) {
        this.$router.push('/booking/social-care-worker').catch(() => {});
      } else {
        this.$router.push('/booking/resident').catch(() => {});
      }
    }
  }
});
</script>
