<template>
  <v-row justify="center">
    <v-col cols="12" class="pt-16">
      <v-container>
        <back-button @click.native="$router.go(-1)"></back-button>
        <h3 class="mb-8 home-header-3">
          Are you using this service on behalf of a child who you have parental responsibility for?
        </h3>
        <v-radio-group
          v-model="behalfOfChild"
          v-on:change="onRadioGroupClicked"
          class="mb-4"
        >
          <v-radio
            v-on:keyup.enter="
              behalfOfChild = 'No';
              onRadioGroupClicked();
            "
            label="I am booking for myself"
            value="No"
            color="#006078"
            class="ml-4 px-8 py-4 black--text"
            :ripple="false"
          ></v-radio>
          <v-radio
            v-on:keyup.enter="
              behalfOfChild = 'Yes';
              onRadioGroupClicked();
            "
            label="I am booking on behalf of a child"
            value="Yes"
            color="#006078"
            class="ml-4 px-8 py-4 black--text"
            :ripple="false"
          ></v-radio>
        </v-radio-group>
        <v-btn
          @click="onAfterContinueClicked"
          class="v-btn--primary"
          :disabled="!behalfOfChild"
        >
          Continue
        </v-btn>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
import Vue from 'vue';
import BackButton from '@/components/BackButton';

export default Vue.extend({
  components: { BackButton },
  name: 'StepBehalfOfChild',
  data: () => ({
    behalfOfChild: null
  }),
  mounted: function() {
  },
  methods: {
    onRadioGroupClicked() {
      this.$store.commit(
        'setBehalfOfChild',
        this.behalfOfChild === 'Yes'
      );
    },
    onAfterContinueClicked() {
      this.$gtag.event('Using system on behalf of child question answered', {})
      if (this.behalfOfChild === 'No') {
        this.$router.push('/booking/not-eligible-age?m=child').catch(() => {});
      } else {
        this.$router.push('/booking/booking-types-1').catch(() => {});
      }
    }
  }
});
</script>
