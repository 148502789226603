export default [
  {
    value: 2,
    text: '80 years of age and over',
    textAt: 'at 31st March 2021',
    minDate: '1900-01-01',
    maxDate: '1941-03-31',
    minDateAsDate: new Date(1900, 0, 1),
    maxDateAsDate: new Date(1941, 2, 31),
    ifDate: true
  },
  {
    value: 3,
    text: '75 - 79 years of age',
    textAt: 'at 31st March 2021',
    minDate: '1941-04-01',
    maxDate: '1946-03-31',
    minDateAsDate: new Date(1941, 3, 1),
    maxDateAsDate: new Date(1946, 2, 31),
    ifDate: true
  },
  {
    value: 4,
    text: '70 - 74 years of age',
    textAt: 'at 31st March 2021',
    minDate: '1946-04-01',
    maxDate: '1951-03-31',
    minDateAsDate: new Date(1946, 3, 1),
    maxDateAsDate: new Date(1951, 2, 31),
    ifDate: true
  },
  {
    value: 6,
    text: '65 - 69 years of age',
    textAt: 'at 31st March 2021',
    minDate: '1951-04-01',
    maxDate: '1956-03-31',
    minDateAsDate: new Date(1951, 3, 1),
    maxDateAsDate: new Date(1956, 2, 31),
    ifDate: true
  },
  {
    value: 9,
    text: '60 - 64 years of age',
    textAt: 'at 31st March 2021',
    minDate: '1956-04-01',
    maxDate: '1961-03-31',
    minDateAsDate: new Date(1956, 3, 1),
    maxDateAsDate: new Date(1961, 2, 31),
    ifDate: true
  },
  {
    value: 10,
    text: '55 - 59 years of age',
    textAt: 'at 31st March 2021',
    minDate: '1961-04-01',
    maxDate: '1966-03-31',
    minDateAsDate: new Date(1961, 3, 1),
    maxDateAsDate: new Date(1966, 2, 31),
    ifDate: true
  },
  {
    value: 11,
    text: '50 - 54 years of age',
    textAt: 'at 31st March 2021',
    minDate: '1966-04-01',
    maxDate: '1971-03-31',
    minDateAsDate: new Date(1966, 3, 1),
    maxDateAsDate: new Date(1971, 2, 31),
    ifDate: true
  },
  {
    value: 12,
    text: '45 - 49 years of age',
    textAt: 'at 31st March 2021',
    minDate: '1971-04-01',
    maxDate: '1976-03-31',
    minDateAsDate: new Date(1971, 3, 1),
    maxDateAsDate: new Date(1976, 2, 31),
    ifDate: true
  },
  {
    value: 13,
    text: '40 - 44 years of age',
    textAt: 'at 31st March 2021',
    minDate: '1976-04-01',
    maxDate: '1981-03-31',
    minDateAsDate: new Date(1976, 3, 1),
    maxDateAsDate: new Date(1981, 2, 31),
    ifDate: true
  },
  {
    value: 14,
    text: '35 - 39 years of age',
    textAt: 'at 30th April 2021',
    minDate: '1981-04-01',
    maxDate: '1986-04-30',
    minDateAsDate: new Date(1981, 3, 1),
    maxDateAsDate: new Date(1986, 3, 30),
    ifDate: true
  },
  {
    value: 15,
    text: '30 - 34 years of age',
    textAt: 'at 30th April 2021',
    minDate: '1986-05-01',
    maxDate: '1991-04-30',
    minDateAsDate: new Date(1986, 4, 1),
    maxDateAsDate: new Date(1991, 3, 30),
    ifDate: true
  },
  {
    value: 16,
    text: '25 - 29 years of age',
    textAt: 'at 31st July 2021',
    minDate: '1991-05-01',
    maxDate: '1996-07-31',
    minDateAsDate: new Date(1991, 4, 1),
    maxDateAsDate: new Date(1996, 6, 31),
    ifDate: true,
  },
  {
    value: 17,
    text: '18 - 24 years of age',
    textAt: 'at 31st October 2021',
    minDate: '1996-08-01',
    maxDate: '2003-10-31',
    minDateAsDate: new Date(1996, 7, 1),
    maxDateAsDate: new Date(2003, 9, 31),
    ifDate: true,
  },
  {
    value: 18,
    text: '16 - 17 years of age',
    textAt: 'at 31st July 2021',
    minDate: '2003-11-01',
    maxDate: '2005-07-31',
    minDateAsDate: new Date(2003, 10, 1),
    maxDateAsDate: new Date(2005, 6, 31),
    ifDate: true,
    ifImmunocompromised: true
  },
  {
    value: 19,
    text: '12 - 15 years of age',
    textAt: 'at 31st July 2021',
    minDate: '2005-08-01',
    maxDate: '2010-07-31',
    minDateAsDate: new Date(2005, 7, 1),
    maxDateAsDate: new Date(2010, 6, 31),
    ifDate: true,
    ifImmunocompromised: true
  },
  {
    value: 20,
    text: '5 - 11 years of age',
    textAt: 'at 31st July 2021',
    minDate: '2010-08-01',
    maxDate: '2017-07-31',
    minDateAsDate: new Date(2010, 7, 1),
    maxDateAsDate: new Date(2017, 6, 31),
    ifDate: true,
    ifImmunocompromised: true
  },
  {
    value: 21,
    text: 'None of the above',
    minDate: '1900-01-01',
    maxDate: '2009-07-31',
    minDateAsDate: new Date(1900, 0, 1),
    maxDateAsDate: new Date(2009, 7, 31),
    ifNone: true
  },

/*  {
    value: 8,
    text: 'Carer of an older person or someone with underlying health conditions',
    minDate: '1900-01-01',
    maxDate: '2003-03-31'
  },*/
]