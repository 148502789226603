export default [
  {
    label: 'I am in a clinical risk group',
    label2: 'At Risk',
    value: 'At Risk',
    onlyFor3Dose: false
  },
  {
    label: 'I am immunosuppressed',
    label2: 'Immunosuppressed',
    value: 'Immunosuppressed',
    onlyFor3Dose: false
  },
  {
    label: 'I am a family carer',
    label2: 'Family Carer',
    value: 'Family Carer',
    only3Dose: false
  },
  {
    label: 'I am a foster carer',
    label2: 'Foster Carer',
    value: 'Foster Carer',
    only3Dose: false
  },
  {
    label: 'I am a care home resident',
    label2: 'Care Home Resident',
    value: 'Care Home Resident',
    onlyFor3Dose: true
  },
  {
    label: 'I am pregnant',
    label2: 'Pregnant',
    value: 'Pregnant',
    onlyFor3Dose: false
  },
  {
    label: 'I am a bird culler',
    label2: 'Bird culler',
    value: 'Bird culler',
    onlyFor3Dose: false
  },
  {
    label: 'None of the above',
    label2: 'None of the above',
    value: '',
    onlyFor3Dose: false
  }
];
