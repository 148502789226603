<template>
  <v-row justify="center">
    <v-col cols="12" class="pt-16">
      <v-container>
        <back-button @click.native="$router.go(-1)"></back-button>
        <h3 class="mb-8 home-header-3">
          When did you receive your last COVID vaccination?
        </h3>
        <div class="mt-12 mb-16">
          <v-date-picker
            v-model="selectedDate"
            @change="scrollToContinue"
            :max="todayString"
            color="#006078"
            elevation="1"
          ></v-date-picker>
        </div>
        <v-btn
          ref="continue"
          @click="onAfterContinueClicked"
          class="v-btn--primary"
          :disabled="!selectedDate"
        >
          Continue
        </v-btn>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import BackButton from '@/components/BackButton';
import moment from 'moment/moment';

export default Vue.extend({
  components: { BackButton },
  name: 'StepReceivedLastDose',
  data: () => ({
    selectedDate: null
  }),
  computed: {
    ...mapGetters([
    ]),
    todayString() {
      return moment().format('YYYY-MM-DD')
    }
  },
  methods: {
    scrollToContinue() {
      this.$refs.continue.$el.scrollIntoView({
        behavior: 'smooth'
      });
    },
    saveDateWhenLastDoseReceived() {
      this.$store.commit(
        'setDateWhenLastDoseReceived',
        moment(this.selectedDate)
      );
      this.$gtag.event('Last dose date saved', {
        event_label: `${this.selectedDate}`
      })
    },
    onAfterContinueClicked() {
      this.saveDateWhenLastDoseReceived();
      this.$router.push('/booking/demographics').catch(() => {});
    }
  },
  mounted(){
    this.$gtag.event('Patient previously vaccinated', {})
  }
});
</script>
