<template>
  <v-row justify="center">
    <v-col cols="12" class="pt-16">
      <v-container>
        <back-button @click.native="$router.go(-1)"></back-button>
        <h3 class="mb-8 home-header-3">
          Are you a health and social care worker?
        </h3>
        <v-radio-group
          v-model="healthAndSocialCareWorker"
          v-on:change="onRadioGroupClicked"
          class="mb-4"
        >
          <v-radio
            v-on:keyup.enter="
              healthAndSocialCareWorker = 'Yes';
              onRadioGroupClicked();
            "
            label="Yes"
            value="Yes"
            color="#006078"
            class="ml-4 px-8 py-4 black--text"
            :ripple="false"
          ></v-radio>
          <v-radio
            v-on:keyup.enter="
              healthAndSocialCareWorker = 'No';
              onRadioGroupClicked();
            "
            label="No"
            value="No"
            color="#006078"
            class="ml-4 px-8 py-4 black--text"
            :ripple="false"
          ></v-radio>
        </v-radio-group>
        <v-btn
          @click="onAfterContinueClicked"
          class="v-btn--primary"
          :disabled="!healthAndSocialCareWorker"
        >
          Continue
        </v-btn>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import BackButton from '@/components/BackButton';

export default Vue.extend({
  components: { BackButton },
  name: 'StepHealthAndSocialCareWorker',
  data: () => ({
    healthAndSocialCareWorker: null
  }),
  mounted: function() {
    this.$store.commit('setIfTrustPrimaryEmployer', null);
    this.$store.commit('setFrontlineHealthAndSocialCareWorker', null);
    this.$store.commit('setTrust', null);
  },
  computed: {
    ...mapGetters(['getFlowName'])
  },
  methods: {
    onRadioGroupClicked() {
      this.$store.commit(
        'setHealthAndSocialCareWorker',
        this.healthAndSocialCareWorker === 'Yes'
      );
    },
    onAfterContinueClicked() {
      if (this.healthAndSocialCareWorker === 'Yes') {
        this.$router.push('/booking/trust').catch(() => {});
      } else {
        this.$router.push('/booking/resident').catch(() => {});
      }
      this.$gtag.event(`Social Care worker answered` + `${this.getFlowName}`)
    }
  }
});
</script>
