<template>
  <v-row justify="center">
    <v-col cols="12" class="pt-16">
      <v-container>
        <back-button @click.native="$router.go(-1)"></back-button>
        <v-row>
          <v-col cols="12" lg="6">
            <h3 class="mb-8 home-header-3">
              Which of the following apply to you?
            </h3>
            <div
              v-for="demographicsItem in demographicsItems"
              :key="demographicsItem.value"
            >
              <v-checkbox
                v-model="demographics"
                v-on:change="onCheckboxGroupClicked"
                v-on:keyup.native.enter="
                  addRemoveDemographics(demographicsItem.value)
                "
                :label="demographicsItem.label"
                :value="demographicsItem.value"
                color="#006078"
                class="ml-4 black--text"
                :ripple="false"
              ></v-checkbox>
            </div>
            <v-btn @click="onAfterContinueClicked" class="mt-8 v-btn--primary">
              Continue
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import BackButton from '@/components/BackButton';
import demographicsItems from '@/data/demographics';

export default Vue.extend({
  components: {
    BackButton
  },
  name: 'StepBookingTypes',
  data: () => ({
    demographicsItems: demographicsItems,
    demographics: []
  }),
  mounted: function() {
    this.$store.commit('setDemographics', []);
  },
  computed: {
    ...mapGetters(['getHealthAndSocialCareWorker', 'getIfCovid3DoseLetter'])
  },
  methods: {
    addRemoveDemographics(value) {
      const index = this.demographics.indexOf(value);
      if (index > -1) {
        this.demographics.splice(index, 1);
      } else {
        this.demographics.push(value);
      }
    },
    saveDemographics() {
      this.$store.commit('setDemographics', this.demographics);
    },
    onCheckboxGroupClicked() {
      this.saveDemographics();
    },
    onAfterContinueClicked() {
      this.$gtag.event('Demographics filled', {})
      this.$router.push('/booking/covid-3-dose-letter').catch(() => {});
    }
  }
});
</script>
